
* {
    text-decoration: none;
    box-sizing: border-box;
    
}

.logo{
  width: 100%;
    }

.navbar{
    
    padding: 0;
    background-color: white;
    position: fixed;
    width: 100%;
    z-index: 100;
    box-shadow: 0px 14px 24px 0px #1c0f7d08;
}

.navbar a {
    text-decoration: none;
}

.items{
    padding: 1%;
    color: #1E91D6;
    cursor: pointer;
    display: inline;
    width: 100%;
}

.mobile-menu-icon{
    display: none;
}


.taille_imageCeos{
    height: 70px;
    margin: 10px;
}

.nav-links{
    padding-top: 0%;
    margin: 0;
}

.nav-links li{
    border-radius: 10px;
    margin-left: 8%;
}

.nav-links li:hover{
    color: white;
    background-color: #1E91D6;
}

.current{
    border-radius: 10px;
    margin-left: 10%;
    color: white;
    background-color: #1E91D6;
}

.contacter{
    border-radius: 10px;
    border: 2px solid #1E91D6;
    
}

/* bouton */
.mobile-menu-icon{
    display: none;

    margin-top: 10px;
    color: #1E91D6;
    border: none;
    size: 30px;
    background-color: white;
}


@media screen and (max-width:950px) {
  

    .items{
        width: 100%;
        line-height: 250%;
        margin-left: 0%;
    }

    .nav-links li{
        display: none;
    }
    
    /* MOBILE VERSION */
    .nav-links-mobile{
        height: 100vh;
        text-align: center;
        font-size: 5vh;
    }

    .nav-links-mobile li{
        display: block;
    }

    .nav-links-mobile li:hover{
        font-weight: bold;
    }

    .mobile-menu-icon{
        display: block;
        position: absolute;
        right: 5%;
        z-index: 200;
    }

    .nav-links-mobile img{
        margin-top: 5%;
        margin-bottom: 5%;
        height: 20%;
    }
}
