html {
    overflow-x: hidden;
}

body {
    margin: 0;
    padding: 0;
    perspective: true;
    width: 100% !important;
}

.description {
    width: 65%;
}

.description_ligne {
    display: flex;
    width: 1300px !important;
    margin-left: auto;
    margin-right: auto;
}

.vos-projets {
    margin-top: 6.5vw;
}

/*--cercle--begin--*/
/* tous ce qui est fixe*/



/* beginning page 2 qui-sommes-nous*/
#pour_position5 {
    position: absolute;
    transform: translate(-50%, -60%);
    /* par rapport à lui même*/
    width: 380px;
    height: 380px;
    top: 350px;

}

.cercle5 {
    background: #68E1FD;
    border-radius: 50%;
    z-index: -1;
}

#pour_position6 {
    position: absolute;
    transform: translate(-50%, -50%);
    /* par rapport à lui même*/
    width: 380px;
    height: 380px;
    top: 1900px;
}

#pour_position7 {
    position: absolute;
    transform: translate(250%, -50%);
    /* par rapport à lui même*/
    width: 380px;
    height: 380px;
    top: 2600px;
}

.cercle6 {
    background: #04295D;
    border-radius: 50%;
    z-index: -1;
}

#pour_position8 {
    position: absolute;
    transform: translate(-50%, -50%);
    /* par rapport à lui même*/
    width: 380px;
    height: 380px;
    top: 3400px;
}

.cercle7 {
    background: #1E91D6;
    border-radius: 50%;
    z-index: -1;
}

#pour_position9 {
    position: absolute;
    transform: translate(250%, -50%);
    /* par rapport à lui même*/
    width: 380px;
    height: 380px;
    top: 4100px;
}

.cercle5 {
    background: #68E1FD;
    border-radius: 50%;
    z-index: -1;
}

#pour_position10 {
    position: absolute;
    transform: translate(250%, -60%);
    /* par rapport à lui même*/
    width: 380px;
    height: 380px;
    top: 850px;
}

#pour_position11 {
    position: absolute;
    transform: translate(-50%, -60%);
    /* par rapport à lui même*/
    width: 380px;
    height: 380px;
    top: 1350px;

}



/*--cercle--end--*/
/*----------SECTION1--BEGIN-----------*/


/* .my-container1{
    border: 1px solid green;
    
}

.my-row1{
    border: 3px solid red;
 
    
}

.my-col1{
    border: 3px dotted blue;
   
} */


.section1 {
    width: 100%;
}


.bloc1 {
    width: 600px;
    min-height: 500px;
    margin-left: 150px;

}

.titre1 {
    margin-top: 35%;
    text-align: start;

    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    color: #1E91D6;
}

.title_ceos {
    font-size: 2.5rem;
    color: #1E91D6;

}

.texte1 {
    margin-top: 20px;
    margin-bottom: 45px;
    text-align: start;
    font-size: 1.2em;
}

.texte_3 {
    font-size: 1.2em;
    ;
}

.margin_top {
    margin-top: 1.5em;
}

.taille_image1 {
    object-fit: contain;
    height: 120%;
    width: 120%;
}

.bgr1 {
    background-color: #1E91D6;
    width: 200px;
    padding: 2% 3% 3% 3%;
    border-radius: 20px;
    color: white;
    text-align: center;
    margin-right: 10%;
    padding-top: 10px;




}

.bgr2 {
    border: solid;
    width: 180px;
    padding: 2% 2% 2% 2%;
    border-radius: 20px;
    color: black;
    text-align: center;
}

.hauteur1a {
    height: 650px;
}

.hauteur1b {
    height: 650px;
}



/*----------SECTION1--END-----------*/
/*----------SECTION2--BEGIN-----------*/

/* .my-container2{
    border: 1px solid green;
    
}

.my-row2{
    border: 3px solid red;
 
    
}

.my-col2{
    border: 3px dotted blue;
   
} */
/* height for this part*/
.hauteur2 {
    height: 220px;
}

.bgr {
    background-color: #DCEFF9;
}


.taille_image {
    height: 100px;
    object-fit: contain;
    margin-top: 30px;
    align-self: center;
}

/*---------- + en dessous----------*/
/* 
.my-container2h{
    border: 1px solid green;
    
}

.my-row2h{
    border: 3px solid red;
 
    
}

.my-col2h{
    border: 3px dotted blue;
   
} */
/* height for this part*/
.hauteur2h {
    height: 300px;
}



/*----------SECTION2--END----------*/
/*----------SECTION3--BEGIN----------*/
/* .my-container3{
    border: 1px solid green;
   
}

.my-row3{
    border: 3px solid red;
 
    
}

.my-col3{
    border: 3px dotted blue;
   
} */
/* height for this part*/
.hauteur3a {
    height: 450px;
}

/* height for this part*/
.hauteur3b {
    height: 150px;
}



.texte_C {
    color: rgb(0, 0, 0);
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 3vw;
}
.texte_D{
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 3vw;
}

.texte_T {
    color: #1E91D6;
    font-size: 2rem;
    font-weight: bold;
}

.texte_S {
    color: #ffffff;
    font-size: 25px;
    font-weight: bold;
    background-color: #1E91D6;
    padding: 10px 35px 10px 35px;
    border-radius: 5px;

}



/*----------SECTION3--END----------*/
/*----------SECTION4--BEGIN----------*/
/* .my-container4{
    border: 1px solid green;
   
}

.my-row4{
    border: 3px solid red;
 
    
}

.my-col4{
    border: 3px dotted blue;
   
} */
/* height for this part*/
.hauteur4 {
    height: 300px;
}


.texte_1 {
    color: rgb(0, 0, 0);
    font-size: 36px;
    font-weight: bold;

}

.texte_2 {
    color: #1E91D6;
    font-size: 36px;
    font-weight: bold;

}

.taille_image2 {
    height: 380px;
    object-fit: contain;

}

.image_illustre1 {
    max-width: 3rem;
    margin-top: 30%;
    /*centrage img*/
    margin-left: 30%;
    /*centrage img*/

}



/*---------SECTION4--END----------*/
/*---------SECTION5--BEGIN----------*/
/* .my-container5{
    border: 1px solid green;
   
}

.my-row5{
    border: 3px solid red;
 
    
}

.my-col5{
    border: 3px dotted blue;
   
} */
.col-lg-8 {
    flex: 0 0 77%;
}

/* height for this part*/
.hauteur5 {
    height: 500px;
}

.hauteur5b {
    height: 200px;
}

.couleur_blanche {
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-weight: bold;
    z-index: 100;
    text-align: center;

}

.hauteur5>p {
    width: 45%;
}

.monFond {
    background-image: url(./images/font1.png);
    background-repeat: no-repeat;

    background-size: cover;
    min-height: 500px;

}


.texte_S5 {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    background-color: #1E91D6;
    padding: 10px 35px 10px 35px;
    border-radius: 10px;
    margin-top: 1em;

}

/*--------SECTION5--END----------*/
/*----------FOOTER--BEGIN----------*/
/*
.my-containerf{
    border: 1px solid green;
   
}

.my-rowf{
    border: 3px solid red;
 
    
}

.my-colf{
    border: 3px dotted blue;
   
} 
*/
/*height for this part*/

.footer_hauteur {

    height: 70%;
    background-color: white;
    width: 100%;
    text-decoration: none;
    box-shadow: 1px -2px 24px 0px #1c0f7d08;

}

ul.espaceFooter1 {
    list-style-type: none;
}


.taille_imagef {
    height: 50px;
    object-fit: contain;

}



.espaceFooter1 {
    padding-top: 2%;
    margin-bottom: 25px;
    line-height: 50%;
    width: 100%;

}

.espaceFooter2 {
    list-style-type: none;
    margin-top: 10px;
    line-height: 50%;
}

.logo-footer {
    height: 60px;
    margin-bottom: 15px;
}


.reseau img {
    margin-left: 10px;

}

.footer_hauteur li {
    color: black;
}

.li-width {
    width: 100%;
}

@media screen and (max-width:900px) {



    .font_sizeFooter {
        font-size: 12px;
    }
    .description_ligne {
        width: 100% !important;
    }
    .titre1{
        font-size: 2.5rem;
    }
    .description {
        width: 100%;
    }
    ul {
        padding-inline-start: 0px; 
    }
    main{
        max-width: 95%;
    }
    .navbar{
        max-width: 95%;
    }
    head{
        max-width: 95%;
    }
    .quis{
        display: none;
    }
    body{
        margin: 0;
    }
    .reseaux{
        max-width: 95%;
    }
    .cgv{
        max-width: 95%;
    }
    .contacter {
        border: none;
    }
    .logo > li {
        font-size: 1.2rem ;
    }
    .nav-links-mobile {
        font-size: 4.2vh;
    }
    .nav-links-mobile img {
        height: 125px;
        margin-top: 11%;
        margin-bottom: 21%;
    }
    .hauteurB2 {
        height: 450px;
    }

}


/*---------FOOTER--END----------*/
/*---------SECTIONB1--BEGIN----------*/
/* 
.my-containerB1{
    border: 1px solid green;
   
}

.my-rowB1{
    border: 3px solid red;
 
    
}

.my-colB1{
    border: 3px dotted blue;
   
} */

/* height for this part*/
.hauteurB1 {
    height: 185px;
}

/* height for this part*/
.hauteurB12 {
    height: 350px;

}

/* height for this part*/
.hauteurB13 {
    height: 350px;
}

.hauteurB13 p {
    width: 100%;
}



.texte_BL1 {

    font-size: 30px;
    font-weight: bold;
    color: #1E91D6;
}


.texte_BL2 {
    font-size: 1.2rem;
}





.taille_imageB1 {
    height: 90px;
    object-fit: contain;



}

.taille_imageMagaye {
    height: 300px;
    object-fit: contain;

}

.taille_imageLaurent {
    height: 300px;
    object-fit: contain;

}



.titreB1 {
    color: #1E91D6;
    font-weight: bold;
    /* margin-top: 30px; */

}

.titreB2 {
    color: #1E91D6;
    font-weight: bold;
    font-size: 35px;
    background-color: #DCEFF9;
    padding: 25px 200px 25px 200px;
    border-radius: 8px;
}



/*--------SECTIONB1--END----------*/
/*---------SECTIONB2--BEGIN----------*/

/* .my-containerB2{
    border: 1px solid green;
   
} */

.my-rowB2 {
    /* border: 3px solid red; */
    background-color: #1E91D6;

}

/* .my-colB2{
    border: 3px dotted blue;
 
   
} */

/* height for this part*/
.hauteurB2 {
    height: 300px;
}

.texte_BB1 {

    font-size: 2.5rem;
    font-weight: bold;
    color: #ffffff;
}


.texte_BB2 {

    font-size: 1.2em;
    font-weight: bold;
    color: #ffffff;
}



/*--------SECTIONB2--END----------*/
/*---------SECTIONBA--BEGIN----------*/
/* 
.my-containerBA{
    border: 5px solid rgb(30, 128, 0);
}

.my-rowBA{
    border: 3px solid red;
 
    
}

.my-colBA{
    border: 3px dotted blue;
   
} */

.couleurG {
    background-color: #F5F5F5;
    border-radius: 5px;
}

/* height for this part*/
.hauteurBA {
    height: 160px;
}

.taille_imageBA {
    height: 150px;
    object-fit: contain;

}


/*--------SECTIONBA--END----------*/
/*---------SECTIONBB--BEGIN----------*/

/* 
.my-containerBB{
    border: 5px solid rgb(30, 128, 0);
}

.my-rowBB{
    border: 3px solid red;
 
    
}

.my-colBB{
    border: 3px dotted blue;
   
} */

.couleurGG {
    background-color: rgb(189, 172, 81);
    border-radius: 5px;
}

/* height for this part*/
.hauteurBB {
    height: 170px;
}

.taille_imageBB {
    height: 75px;
    object-fit: contain;

}
.logo_qui{
    height: 125px !important;
    object-fit: contain;
}
.margin-qui{
    margin-bottom: 50px;
}


/*--------SECTIONBB--END----------*/
/*---------SECTIONBC--BEGIN----------*/

/* 
.my-containerBB{
    border: 5px solid rgb(30, 128, 0);
}

.my-rowBB{
    border: 3px solid red;
 
    
}

.my-colBB{
    border: 3px dotted blue;
   
} */

.couleurGG {
    background-color: rgb(189, 172, 81);
    border-radius: 5px;
}

/* height for this part*/
.hauteurBC {
    height: 400px;
}

.taille_imageBC {
    height: 150px;
    object-fit: contain;

}




/*--------SECTIONBC--END----------*/