body{
    margin: 0;
    padding: 0;
}

/*---------SECTIONC1--BEGIN----------*/
/* 
.my-containerC1{
    border: 1px solid green;
   
}

.my-rowC1{
    border: 3px solid red;
 
    
}

.my-colC1{
    border: 3px dotted blue;
   
} */

/* height for this part*/
.hauteurC1{
    height: 250px;
}


.taille_imageC1{
    height:90px;
    object-fit: contain;
    margin-top: 4.3em;
  
    }

.titreC1{
    color: #1E91D6;
    font-weight: bold; 
    font-size: 2.5rem;
    
    
}

.titreC2{
    color: #1E91D6;
    font-weight: bold; 
    font-size: 35px;
    padding-top: 3%;
}

@media screen and (max-width:1030px) {
  
    
    .titreC2{
    

    
        font-size: 20px;
        
      
    }
    
}


@media screen and (max-width:600px) {
  
    
    
    .titreC2{
    
        font-size: 17px;
        
      
    }
    
}
   
   



/*--------SECTIONC1--END----------*/
/*---------SECTIONC2--BEGIN----------*/

/* .my-containerC2{
    border: 1px solid green;
   
} */

.my-rowC2{
    /* border: 3px solid red; */
 background-color: #1E91D6;
    
}

/* .my-colC2{
    border: 3px dotted blue;
    
} */

/* height for this part*/
.hauteurC2{
    height: 350px;
}


.taille_imageC2{
    height:250px;
    object-fit: contain;
  
    }

.couleurC1{
 
    color: white;
}


/*--------SECTIONC2--END----------*/
/*---------SECTIONC3--BEGIN----------*/

/* .my-containerC3{
    border: 1px solid green;
   
}

.my-rowC3{
    border: 3px solid red;

    
}

.my-colC3{
    border: 3px dotted blue;
    
} */

/* height for this part*/
.hauteurC3{
    height: 250px;
}


.taille_imageC3{
    height:250px;
    object-fit: contain;
  
    }

.couleurC3{
 
    color: #1E91D6;
}

.text_C3a{
   font-size: 20px;
}


/*--------SECTIONC3--END----------*/
/*---------SECTIONC4--BEGIN----------*/

/* .my-containerC4{
    border: 1px solid green;
   
} */

.my-rowC4{
    /* border: 3px solid red; */
 background-color: #1E91D6;
    
}

/* .my-colC4{
    border: 3px dotted blue;
    
} */

/* height for this part*/
.hauteurC4{
    height: 350px;
}


.taille_imageC4{
    height:250px;
    object-fit: contain;
  
    }

.couleurC4{
 
    color: white;
}


/*--------SECTIONC4--END----------*/
/*----------SECTIONC5--BEGIN-----------*/

/* .my-containerC5{
    border: 1px solid green;
    
}

.my-rowC5{
    border: 3px solid red;
 
    
}

.my-colC5{
    border: 3px dotted blue;
} */
/* height for this part*/
.hauteurC5{
    height: 400px;
}


.taille_imageC5{
    height: 80px;
    object-fit: contain;
    margin-top: 100px;
    align-self: center;
    
    }


/*----------SECTIONC5--END----------*/
/*----------SECTIONC6--BEGIN----------*/
/* .my-containerC6{
    border: 1px solid green;
   
}

.my-rowC6{
    border: 3px solid red;
 
    
}

.my-colC6{
    border: 3px dotted blue;
   
} */
/* height for this part*/
.hauteurC6{
    height: 250px;
}


.texte_C6{
    color: rgb(0, 0, 0);
    font-size: 15px;
    font-weight: bold;
    margin-top: 5vw;
}

.texte_T6{
    color: #1E91D6;
    font-size: 35px;
    font-weight: bold;
}


/*----------SECTIONC6--END----------*/
/*---------SECTIONC7--BEGIN----------*/

/* .my-containerC7{
    border: 1px solid green;
 
}

.my-rowC7{
    border: 3px solid red;
 
    
}

.my-colC7{
    border: 3px dotted blue;
   
   
} */

/* height for this part*/
.hauteurC7{
    height: 300px;
}

.couleurC7{
 color: white;
 font-size: 35px;
 margin-top: 90px;
}

.bgrC7{
     background-color: #1E91D6;
}

  
.taille_imageC7{
    height: 100px;
    object-fit: contain;
   margin-top: 60px;
  align-self: center;
    }


/*--------SECTIONC7--END----------*/
/*---------SECTIONC8--BEGIN----------*/
/* 
.my-containerC8{
    border: 1px solid green;
 
}

.my-rowC8{
    border: 3px solid red;
 
    
}

.my-colC8{
    border: 3px dotted blue;
   
   
} */

/* height for this part*/
.hauteurC8{
    height: 350px;
}

.hauteurC8B{
    height: 50px;
}

.couleurC8{
 color: white;
 font-size: 20px;
}

.bgr8{
     background-color: #DCEFF9;
}

  
.taille_imageC8{
    height: 300px;
    object-fit: contain;
    align-self: center;
    }

    .texte_C8{
        color: rgb(0, 0, 0);
        font-size: 18px;
        font-weight: bold;
        margin-top: 5vw;
      
    }
    
    .texte_T8{
        color: #1E91D6;
        font-size: 30px;
        font-weight: bold;
      
    }
    
    

/*--------SECTIONC8--END----------*/
/*---------SECTIONC9--BEGIN----------*/
/* 
.my-containerC9{
    border: 1px solid green;
 
}

.my-rowC9{
    border: 3px solid red;
 
    
}

.my-colC9{
    border: 3px dotted blue;
   
   
} */

/* height for this part*/
.hauteurC9{
    height: 450px;
}

.hauteurC10{
    height: 250px!important;
}


.hauteurC9B{
    height: 100px;
}

.couleurC9{
 color: white;
 font-size: 20px;
}

.bgr9{
     background-color: #DCEFF9;
}

  
.taille_imageC9{
    height: 300px;
    object-fit: contain;
  
  align-self: center;
    }

    .texte_C9{
        color: rgb(0, 0, 0);
        font-size: 20px;
        font-weight: bold;
        margin-top: 5vw;
    }
    
    .texte_T9{
        color: #1E91D6;
        font-size: 30px;
        font-weight: bold;
      
    }
    
    

/*--------SECTIONC9--END----------*/
/*---------SECTIONC10--BEGIN----------*/

/* .my-containerC10{
    border: 1px solid green;
 
}

.my-rowC10{
    border: 3px solid red;
 
    
}

.my-colC10{
    border: 3px dotted blue;
   
   
} */

/* height for this part*/
.hauteurC10{
    height: 350px;
    
}

.couleurC10{
 color: white;
 font-size: 20px;
}

.bgr10{
     background-color: #DCEFF9;
}

  
.taille_imageC10{
    height: 100px;
    object-fit: contain;
  align-self: center;
  
    }


/*--------SECTIONC10--END----------*/
/*---------SECTIOND1--BEGIN----------*/
/* 
.my-containerD1{
    border: 1px solid green;
   
}

.my-rowD1{
    border: 3px solid red;
 
    
}

.my-colD1{
    border: 3px dotted blue;
   
} */

/* height for this part*/
.hauteurCD1{
    height: 250px;
}


.taille_imageD1{
    height:90px;
    object-fit: contain;
    margin-top: 4%;
  
    }

.titreD1{
    color: #1E91D6;
    font-weight: bold; 
    font-size: 2.5rem;
}

.titreD2{
    color: #1E91D6;
    font-weight: bold; 
    font-size: 2.5rem;
}



/*--------SECTIOND1--END----------*/
/*----------SECTIOND2--BEGIN-----------*/
/*
.my-containerD2{
    border: 1px solid green;
    
}

.my-rowD2{
    border: 3px solid red;
 
    
}

.my-colD2{
    border: 3px dotted blue;
   
}
*/
/* height for this part*/
.hauteurD2{
    height: 500px;
}


  
.taille_imageD2{
    height: 380px;
    object-fit: contain;
    align-self: center;
    }

.taille_imageD2:hover{
    transition-duration: 500ms;
    transform: scale(1.1);
}

.taille_imageD2olo{
    height: 400px;
    object-fit: contain;
    margin-top: -25px;
    align-self: center;
}

.taille_imageD2olo:hover{
    transition-duration: 500ms;
    transform: scale(1.1);
}

.bgrD2{
    font-size: 40px;
    font-weight: bold;
}

.bgrD2olo{
    font-size: 40px;
    font-weight: bold;
    margin-top: 25px;
    
}
@media screen and (max-width:990px) {
  
    
    
    .hauteurD2{
        height: 1200px;
    }
    
    .img-acceuil{
        display: none;
    }

    
}
@media screen and (max-width:890px) {
    .hauteurB2 {
        height: 450px;
    }
    .texte_S {
        font-size: 15px;
    }
    .h1, h1 {
        font-size: 2rem;
    }
    .taille_imageD2 {
        height: 245px;
    }
    .taille_imageD2olo {
    height: 180px;
}
.hauteurD2 {
    height: 697px;
}
.taille_imageD1 {
    height: 80px;
    object-fit: contain;
    margin-top: 20%;
}
}
   

/*----------SECTIOND2--END----------*/
/*---------SECTIOND3--BEGIN----------*/
/* 
.my-containerD3{
    border: 1px solid green;
   
}

.my-rowD3{
    border: 3px solid red;
 
    
}

.my-colD3{
    border: 3px dotted blue;
   
} */

/* height for this part*/
.hauteurD3{
    height: 230px;
}


.taille_imageD3{
    height:90px;
    object-fit: contain;
  
    }

.titreD31{
    color: #1E91D6;
    font-weight: bold; 
    font-size: 2.5rem;
}

.titreD32{
    color: #1E91D6;
    font-weight: bold; 
    font-size: 2rem;
}
.inp-size{
    padding-bottom: 4.5rem;
}
.form-control{
    border-radius: 20px;
}



/*--------SECTIOND3--END----------*/
/*----------SECTIOND4--BEGIN-----------*/

/* .my-containerD4{
    border: 1px solid green;
    
}

.my-rowD4{
    border: 3px solid red;
 
    
}

.my-colD4{
    border: 3px dotted blue;
    background-color: aquamarine;
    border-radius: 15px;
} */
/* height for this part*/
.hauteurD4{
    height: 120px;
}


.bgrD4{
    font-size: 40px;
    font-weight: bold;

}



/*----------SECTIOND4--END----------*/